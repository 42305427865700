body {
	font-family: 'Roboto';
	background: url(./assets/images/bg.jpg) no-repeat center center fixed;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
}
main {
	height: 84vh;
	margin: 8vh 0;
	overflow-y: scroll;
	display: flex;
	flex-direction: column;
}

main::-webkit-scrollbar {
	width: 0.4rem;
}

main::-webkit-scrollbar-track {
	background: #1e1e24;
}

main::-webkit-scrollbar-thumb {
	background: #19b0c1;
}
.messages {
	display: flex;
	flex-direction: column-reverse;
	padding: 1rem;
}
.message {
	display: flex;
	align-items: center;
}

.message p {
	max-width: 500px;
	margin: 10px 0;
	line-height: 24px;
	padding: 10px 20px;
	border-radius: 25px;
	position: relative;
	color: white;
	text-align: center;
}
.message img {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	margin: 2px 5px;
}
.sent {
	flex-direction: row-reverse;
	padding-left: 50px;
}
.received {
	padding-right: 50px;
}

.sent p {
	color: white;
	background: #6c19ff;
	align-self: flex-end;
}
.received p {
	background: #626262;
	color: white;
}
.form {
	display: flex;
	align-items: center;
}
.textfield {
	width: calc(100% - 90px);
}
.textfield input {
	font-size: 20px;
	height: 45px;
}
.textfield div {
	border-radius: 0;
}
.send {
	width: 90px;
	text-align: center;
}
